.about-section {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    overflow: auto;
    padding-bottom: 120px;
  }
  .about-section-box {
    display: flex;
    align-items: flex-end;
    justify-content: center;


  }
  
  .about-img {
    position: fixed;
    bottom: 0;
    left: 0%;
    height: 700px;
    width: auto;
  }
  
  .about-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    transform: translateX(35.5%);
  padding-bottom: 20px;
  margin-bottom: 20px;
  }
  .about-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
    margin-top: -15px;
  }
  .about-box-img {
    margin-top: 5px;
    margin-left: -5px;
    width: 30px;
    height: auto;
  }
  .about-box-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    max-width: 390px;
  }
  .about-box-content-line {
    display: flex;
    align-items: center;

  }
  @media only screen and (min-width: 769px) and (max-width: 1150px) {
    .about-box-content {
      max-width: 300px;
    }
    .about-container {
      transform: translateX(32.5%);
    }

  }
  @media only screen and (min-width: 769px) and (max-width: 970px) {
    .about-box-content-line {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 15px;
    }
    .about-container {
      transform: translateX(29.5%);
    }
  }
  .about-box-content-title {
    color: var(--white, #fff);
    font-family: var(--aqua);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
.about-box-infos {
    display: flex;
    align-items: center;
    gap: 6.364px;
    align-self: stretch;
}
.about-box-infos-hr {
    width: 25.455px;
border: 1px solid var(--blue);

}
.about-box-infos-bold-text {
    color: var(--white-80, rgba(214, 231, 238, 0.80));
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.about-box-infos-light-text {
    color: var(--white-80, rgba(214, 231, 238, 0.80));
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}
.about-box-date {
    color: var(--white, #D6E7EE);
    text-align: center;
    -webkit-text-stroke-width: 1.4463971853256226;
    -webkit-text-stroke-color: var(--white, #D6E7EE);
    font-family: "Aqua Grotesque";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;    
    width: 75px;
    text-align: left;
}
.about-box-infos-diplome-box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.about-box-infos-diplome-box i {
    color: var(--white, #FFF);
    font-size: 25px;


}
.about-box-infos-diplome-text {
    color: var(--white, #FFF);
font-family: var(--inter);
font-size: 15px;
font-weight: 400;
text-align: center;
max-width: 80px;
}

.about-section-right {
  display: flex;
  flex-direction: row;
  position: relative;
}
.about-hr {
  border: 1.5px solid var(--white);
    min-height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 768px) {
  .about-img,
  .about-box-img,
  .about-hr {
    display: none;
  }
  .about-container {
    border-left: none;
    margin-left: 0vw;
    padding: 20px 2%;
    margin-bottom: 20px;
    align-items: center;
    gap: 40px;
    transform: translateX(0);
  }
  .about-section-box {
    justify-content: center;
  }
  .about-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 0px;
    margin-left: 0px;
}
.about-box-content-line {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.about-box-content {
  align-items: center;
}
.about-box-content-title {
  text-align: center;
}
.about-box-infos {
  justify-content: center;
}
.hr-mobile {
  display: block;
  border: 1px solid var(--white);
    width: 30px;

}
}
@media only screen and (min-width: 769px) {
  .hr-mobile {
    display: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1220px) {
  .about-img {
    position: fixed;
    bottom: 0;
    left: -10%;
    height: 700px;
    width: auto;
  }
}




