.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  overflow: auto;
  padding-bottom: 120px;
}
.contact-form-container {
  display: flex;
  max-width: 700px;
  width: 95%;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}
.contact-form-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  width: 100%;
}
.contact-form-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  width: 100%;
}
.contact-form-label {
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.contact-form-label-star {
  color: var(--blue, #018abe);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
}
.contact-form-input,
.contact-form-textarea {
  border-radius: 4px;
  border: 1px solid #aaa;
  background-color: transparent;
  color: var(--white);
  font-family: var(--inter);
  font-size: 14px;
  width: 100%;
  padding: 15px;
  resize: none;
}

.contact-form-textarea {
  border-radius: 4px;
  border: 1px solid #aaa;
}
.contact-form-btn {
  border-radius: 4px;
  height: 48px;
  background: var(--blue, #018abe);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 90%;
  border: none;
}
.contact-form-btn:hover {
  cursor: pointer;
}

.error-message{
  font-family: "Aqua Grotesque";
  color: red;
}

.success-message{
  font-family: "Aqua Grotesque";
  color: var(--white, #fff);
}

@media only screen and (max-width: 768px) {
  .contact-form-row {
    flex-direction: column;
  }
  .contact-form-btn {
    width: 100%;
  }
  .contact-section {
    padding-top: 140px;
  }
}
