.experiences-section {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    overflow: auto;
    padding-bottom: 120px;
  }
  
  .experiences-section-box {
    display: flex;
    align-items: flex-end;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding: 0 5%;
  }
  
  .experiences-section-left {
    flex: 1;
    max-width: 517px;
  }
  
  .experiences-section-hr {
    border: 1.5px solid var(--white);
    height: 100%;
  }
  
  .experiences-section-right {
    max-width: 517px;
    flex: 1;
    height: 100%;
  }
  
  .experiences-section-left-box {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
  
  .experiences-section-left-box i {
    font-size: 25px;
    color: var(--blue);
  }
  
  .experiences-section-left-box-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6.364px;
  }
  
  .experiences-section-left-box-top {
    display: flex;
    justify-content: space-between;
    gap: 27px;
  }
  
  .experiences-section-left-box-top-title {
    margin: 0;
    color: var(--white, #D6E7EE);
    -webkit-text-stroke-width: 1.4463971853256226;
    -webkit-text-stroke-color: var(--white, #D6E7EE);
    font-family: "Aqua Grotesque";
    font-size: 25.455px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .experiences-section-left-box-top-date {
    margin: 0;
    color: var(--white, #D6E7EE);
    text-align: center;
    font-family: "Aqua Grotesque";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .experiences-section-left-box-bot {
    display: flex;
    align-items: center;
    gap: 6.364px;
  }
  
  .experiences-section-left-box-bot-hr {
    margin-right: 10px;
    flex: 1;
    width: 25px;
    border: 1px solid var(--blue);
  }
  
  .experiences-section-left-box-bot-text {
    margin: 0;
    color: var(--white-80, rgba(214, 231, 238, 0.80));
    text-align: center;
    font-family: Inter;
    font-size: 20.364px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .experience-detail {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .experiences-detail-top {
    display: inline-flex;
    align-items: center;
    gap: 24px;
  }
  .experiences-detail-top-text {
    color: var(--white-80, rgba(214, 231, 238, 0.80));
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .experiences-detail-top-img {
    max-width: 150px;
  }
  .experiences-detail-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .experiences-detail-list-title {
    color: var(--white, #D6E7EE);
    text-align: left;
    font-family: "Aqua Grotesque";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.4px;
    text-transform: uppercase;
  }
  ul.experiences-detail-list-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    list-style: square;
    color: var(--white-80, rgba(214, 231, 238, 0.80));

  }
  .experiences-detail-list-item {
    color: var(--white-80, rgba(214, 231, 238, 0.80));
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }


  .experiences-section-right .experience-detail img,
  .experience-detail-mobile img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 10px;
  }
  
  .experiences-section-right .experience-detail h3,
  .experience-detail-mobile h3 {
    margin: 0 0 10px;
  }
  
  .experiences-section-right .experience-detail p,
  .experience-detail-mobile p {
    margin: 0;
  }
  
  .experience-detail-mobile {
    display: none;
  }
  
  @media (max-width: 768px) {
    .experiences-section-box {
      flex-direction: column;
    }

   
  
    .experiences-section-right {
      display: none;
    }
  
    .experiences-section-left-box.active + .experience-detail-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
    }


  }
  
  @media only screen and (max-width: 1090px) {
    .experiences-section-left-box-top-title {
      font-size: 23px;
  }
  .experiences-section-left-box-top {
    flex-direction: column;
    align-items: start;
    gap: 15px;
}
.experiences-detail-top {
  flex-direction: column;
}
  }