@font-face {
    font-family: 'Aqua Grotesque';
    font-style: normal;
    font-weight: normal;
    src: local('Aqua Grotesque'), url('../../public/images/aqua.woff') format('woff');
    }
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
    /*========== Font family ==========*/
  --aqua: 'Aqua Grotesque', sans-serif;
  --inter: 'Lato', sans-serif;


  /*========== Color theme ==========*/
  --grey: #D6E7EE;
  --white: #fff;
  --white80: rgba(255, 255, 255, 0.8);
  --blue: #018ABE;
  --menu-bg: radial-gradient(92.05% 100% at 50% 0%, #2C2F3C 0%, #0F1014 100%);
  --black: #0F1014;
}

/*==================== BASE ====================*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    /*box-shadow: 0 0 0 3px  red inset;*/
  }
  ::-webkit-scrollbar{
    display: none;
  }
  
  body {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(92.05% 100% at 50% 0%, #2C2F3C 0%, #0F1014 100%);  
 overflow: hidden;
  }
  
  html {
    scroll-behavior: smooth;
  }
  h1 {
    color: var(--white-80, rgba(214, 231, 238, 0.80));
    text-align: center;
    -webkit-text-stroke-width: 8.47px;
    -webkit-text-stroke-color: #B0BFC6;
    text-stroke-width: 8.47px; /* Standard syntax */
    text-stroke-color: #B0BFC6; /* Standard syntax */
    font-family: var(--aqua);
    font-size: 8em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 1120px) and (min-width: 841px)  {
    h1 {
        font-size: 6em;
        -webkit-text-stroke-width: 6.47px;
        -webkit-text-stroke-color: #B0BFC6;
        text-stroke-width: 6.47px; /* Standard syntax */
        text-stroke-color: #B0BFC6; /* Standard syntax */
    }
  }
  @media only screen and (max-width: 840px) {
    h1 {
      font-size: 4em;
      -webkit-text-stroke-width: 4.47px;
      -webkit-text-stroke-color: #B0BFC6;
      text-stroke-width: 4.47px; /* Standard syntax */
      text-stroke-color: #B0BFC6; /* Standard syntax */
    }
  }

  ul {
  list-style: none;
  }
  
  a {
  text-decoration: none;
  }
  
  img {
  width: 100%;
  }
  
  li{
  display: flex ;
  align-items: center;
  }
.section {
  width: 100vw;
  height: 90vh;
}
.section-title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;

}
.section-title-box-content {
 display: flex;
 flex-direction: row;
 padding-bottom: 15px;
 align-items: flex-start;
 gap: 2px;
 position: relative;
}
.section-title-img {
  width: 21px;
  height: auto;
  position: absolute;
  top: -11px;
  left: -17px;

}
.section-title-text {
  color: var(--white, #FFF);
font-family: "Aqua Grotesque";
font-size: 25px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.section-title-box-hr {
  border: 1px solid var(--white);
  height: 60px;
  width: 1px;
}
.section-footer-box-hr {
 border: 1px solid var(--white);
 width: 100px;
}
.section-footer-box-text {
  color: var(--white-80, rgba(214, 231, 238, 0.80));
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  padding-top: 15px;
}
.section-footer-img {
  width: 21px;
  padding-top: 15px;

}
.section-footer-title-box-hr {
  border: 1px solid var(--white);
  height: 50px;
  width: 1px;
}

@media only screen and (max-width: 768px) {
  .section-title-box-hr {
    border: 1px solid var(--white);
    height: 40px;
    width: 1px;
  }
}
