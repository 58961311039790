
.hero-section {
display: flex;
flex-direction: column;
align-items: center;
padding-top: 10%;  

}
.hero-section-header {
display: flex;
flex-direction: column;
align-items: start;

}

.hero-section-header-bottom {
    display: inline-flex;
align-items: center;
gap: 20px;
margin-top: -20px;

}
.hero-section-header-bottom-hr {
 border: 1.5px solid var(--blue);
 width: 81px;
}
.hero-section-header-bottom-text {
    color: var(--white-80, rgba(214, 231, 238, 0.80));
    text-align: center;
    font-family: Inter;
    font-size: 22.052px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.hero-img {
 position: absolute;
 bottom: 0;
 max-width: 900px;
 max-height: 900px;
 height: 100%;
 width: auto;
 transform: translateX(40px);
}

@media only screen and (max-width: 768px) {
    .hero-img {
        transform: translateX(0px); 
        max-height: 620px;
    }
}
@media only screen and (max-width: 543px) {
    .hero-section-header {
      align-items: center;
    }
  }
  