/* BtnNav.css */

.component-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.component-container > div {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.btn-nav {
  position: fixed;
  bottom: 15px;
  left: 4%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.btn-nav button {
  border-radius: 86.538px;
  background: var(--white-80, rgba(214, 231, 238, 0.80));
  display: flex;
  padding: 8.654px 8.654px 7.65px 8.654px;
  justify-content: center;
  align-items: center;
  gap: 17.308px;
  border: none;
  color: #0F1014;
  font-size: 20px;
  width: 40px;
  height: 40px;
  transition: opacity 0.5s ease-in-out, background-color 0.3s ease-in-out;
}

.btn-nav button:hover {
  cursor: pointer;
  background-color: var(--white);
}

.btn-nav button:disabled {
  cursor: auto;
}

.next-slide-image {
  cursor: pointer;
  width: 100px;
  height: 100px;
  transition: opacity 0.5s ease-in-out;
}

.fade-button {
  transition: opacity 0.5s ease-in-out, background-color 0.3s ease-in-out;
}

.fade-button:hover {
  background-color: var(--white);
}

/* Mobile view */
@media only screen and (max-width: 768px) {
  .btn-nav {
    position: fixed;
    bottom: 15px;
    left: 90%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    flex-direction: column;
    scale: 0.9;
  }

  .btn-nav.first-slide .next-slide-image {
    display: none;
  }
}

/* Desktop/Tablet view */
@media only screen and (min-width: 769px) {
  .btn-nav.first-slide button {
    display: none;
  }

  .btn-nav.first-slide .next-slide-image {
    display: block;
  }
}
