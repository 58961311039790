.skills-section {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  overflow: auto;
  padding-bottom: 120px;


}
.skills-section-box {
  display: flex;
  align-items: flex-end;
  justify-content: center;

}

.skills-img {
  position: fixed;
  bottom: 0;
  left: 0%;
  height: 700px;
  width: auto;
}

.skills-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  transform: translateX(50%);
  padding-bottom: 10px;
margin-bottom: 10px;
}
.skills-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  margin-top: -15px;
}
.skills-box-img {
  margin-top: 5px;
  margin-left: -5px;
  width: 30px;
  height: auto;
}
.skills-box-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}
.skills-box-content-title {
  color: var(--white, #fff);
  font-family: var(--aqua);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.skills-box-logos {
  display: flex;
  max-width: 517px;
  align-items: center;
  align-content: center;
  gap: 20.029px;
  flex-wrap: wrap;
}
.skills-box-logo {
  max-height: 40px;
  width: auto;
}
.skills-box-logos-langue {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.skills-box-logos-langue-text {
  color: var(--white, #FFF);
font-family: var(--inter);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.skills-box-logos-buble {
  color: var(--white80);
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.skills-box-logos i {
  color: var(--blue);
  font-size: 20px;
}
.skills-section-right {
  display: flex;
  flex-direction: row;
  position: relative;
}
.skills-hr {
  border: 1.5px solid var(--white);
    min-height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
@media only screen and (max-width: 768px) {
  .skills-img,
  .skills-box-img,
  .skills-hr {
    display: none;
  }
  .skills-container {
    border-left: none;
    margin-left: 0vw;
    padding: 20px 5%;
    margin-bottom: 20px;
    align-items: center;
    transform: translateX(0%);
    gap: 40px;
  }
  .skills-section-box {
    justify-content: center;
  }
  .skills-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 0px;
    margin-left: 0px;
}
.skills-box-content {
  align-items: center;
}
.skills-box-content-title {
  text-align: center;
}
.skills-box-infos {
  justify-content: center;
}
.hr-mobile {
  display: block;
  border: 1px solid var(--white);
    width: 30px;

}
.skills-box-logos {
  justify-content: center;
}
}
@media only screen and (min-width: 769px) {
  .hr-mobile {
    display: none;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1220px) {
  .skills-img {
    position: fixed;
    bottom: 0;
    left: -10%;
    height: 700px;
    width: auto;
  }
}

