.fake-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  z-index: 1000;
  top: 150px;
}

.fake-scrollbar {
  width: 5px;
  height: 500px;
  background-color: var(--white80);
  position: relative; /* Ajoutez cette ligne */
}

.moving-scrollbar {
  position: absolute;
  width: 5px;
  background-color: var(--blue);
  top: 0;
}

.fake-links {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.fake-links-item {
  border-radius: 50px;
  background: var(--white-80, rgba(214, 231, 238, 0.80));
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--black);
  padding-top: 2px;
}

@media only screen and (max-width: 768px) {
  .fake-scrollbar {
    display: none;
  }
  .fake-container {
    right: 20px;
    top: 60px;
  }
  .fake-links {
    flex-direction: row;
  }
}
